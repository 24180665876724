import { EmptyObject } from 'type-fest'
import { PlanTypeVariableKey } from '../types/PlanTypeVariableKey'

/**
 * Defines strong types for feature flags, such that whenever the given key is used
 * the variables will be typed appropriately to its value.
 *
 * If a Feature Flag does not have variables, use `EmptyObject`.
 */
export interface FeatureFlags {
  buy_plan_page: Record<PlanTypeVariableKey, string>
  flowcode_limit_upsell_modal: Record<PlanTypeVariableKey, string>
  pricing_plan_group: { group: string }
  enable_geo_smart_rules: EmptyObject
  new_payment_methods: boolean
  disable_automatic_payment_method_types: { defaultPaymentMethods: string }
}

export const GLOBAL_SEARCH_BAR = 'global_search_bar'
export const CRM_FORMS = 'crm_forms'
export const UPDATE_THE_DESKTOP_LEFT_NAV = 'update_the_desktop_left-nav'
export const BUNDLES = 'bundles'
export const VIEW_LIVE_FORM_RESULTS = 'view_live_form_results'
export const UNIFIED_DISCOVER_TEMPLATES = 'unified_discover_templates'
export const UNIFIED_YOUR_TEMPLATES = 'unified_your_templates'
export const UNIFIED_ASSET_MANAGEMENT = 'unified_asset_management_q2_2023'
export const ATLAS_FEATURE_FLAG = 'atlas'
export const BRAND_KIT = 'enable_brand_kit'
export const SHOW_ONBOARDING_FLOW = 'show_onboarding_flow'
export const ONBOARDING_FEATURES = 'onboarding_questions'
export const AUTOSHARING_ASSETS = 'autosharing_assets_q1_2024'
export const LABEL_PAGE_TEMPLATES_IN_UAM = 'label_page_templates_in_uam'
export const UPDATED_VERSION_OF_THE_TRIAL_INTRO_MODAL = 'updated_version_of_the_trial_intro_modal'
export const ASSET_LOADING_STATE_TIMEOUT_DIALOGS = 'asset_loading_state_timeout_dialogs'
export const DESKTOP_ADD_TRIAL_BANNER_TEXT = 'desktop_add_trial_banner_text'
export const ENABLE_BULK_CODE_CREATION_V2 = 'licgv2_bulk_code_creation_ui'
export const ENABLE_DESTINATION_INPUTS_V2 = 'licgv2_destination_inputs'
export const ENABLE_BUTTON_IN_TOP_NAV_V2 = 'licgv2_top_nav_exit_button'
export const ENABLE_CONFIG_ACCORDION_V2 = 'licgv2_config_accordion'
export const STATIC_CODE_CREATION = 'static_code_creation'
